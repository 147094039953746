import React, { useEffect, useState } from "react";
import * as AntIcons from "@ant-design/icons";

const Header = () => {
  const [menuOpen, setmenuOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("click", function (e) {
      if (!document.querySelector(".navbar-custom").contains(e.target)) {
        setmenuOpen(false);
      }
    });
  }, []);

  return (
    <>
      <div className="main-header-section">
        <div
          className="sub__contact d-none d-xl-block sub__contacttwo wow fadeInDown cus__nonesub"
          data-wow-duration="1.2s"
        >
          <div className="container">
            <div className="sub__contact__wrapper">
              <div className="sub__contact-right position-relative">
                <div className="main__logo">
                  <a href="#home" className="logo">
                    <img
                      src={require("../assets/images/logo/logo.png")}
                      alt="logo"
                    />
                  </a>
                </div>
              </div>
              <div className="sub__contact-left">
                <div className="sub__contac-item">
                  <a
                    href="tel:+4733378901"
                    className="d-flex mb-1 align-items-center gap-1"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.75 12.315V14.9671C15.75 15.3608 15.4456 15.6875 15.0529 15.7153C14.7248 15.7384 14.4572 15.75 14.25 15.75C7.62255 15.75 2.25 10.3774 2.25 3.75C2.25 3.54278 2.26159 3.27515 2.28476 2.94713C2.31253 2.55441 2.6392 2.25 3.03289 2.25H5.68508C5.87758 2.25 6.03882 2.39582 6.05815 2.58735C6.0755 2.7593 6.09163 2.89735 6.10655 3.00151C6.25826 4.06104 6.56815 5.06952 7.01152 6.00227C7.08269 6.15199 7.03628 6.33119 6.90139 6.42754L5.28266 7.58385C6.26814 9.88583 8.11417 11.7319 10.4162 12.7174L11.5703 11.1014C11.6679 10.9649 11.8492 10.918 12.0007 10.9899C12.9334 11.4329 13.9418 11.7425 15.0012 11.8938C15.1047 11.9087 15.2419 11.9246 15.4126 11.9419C15.6042 11.9612 15.75 12.1225 15.75 12.315Z"
                        fill="#F3CB5F"
                      />
                    </svg>
                    <span className="address d-block">24/7 Support</span>
                  </a>
                  <a href="tel:+4733378901" className="textp">
                    +61 466091231
                  </a>
                </div>
                <div className="vline-s"></div>
                <div className="sub__contac-item">
                  <a
                    href="mailto:info@45cleaners.com.au"
                    className="d-flex mb-1 align-items-center gap-1"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.84091 14.25L1.5 16.875V3C1.5 2.58579 1.83579 2.25 2.25 2.25H15.75C16.1642 2.25 16.5 2.58579 16.5 3V13.5C16.5 13.9142 16.1642 14.25 15.75 14.25H4.84091ZM5.25 7.5V9H6.75V7.5H5.25ZM8.25 7.5V9H9.75V7.5H8.25ZM11.25 7.5V9H12.75V7.5H11.25Z"
                        fill="#F3CB5F"
                      />
                    </svg>
                    <span className="address d-block">Send Your Message</span>
                  </a>
                  <a href="mailto:info@45cleaners.com.au" className="textp">
                    info@45cleaners.com.au
                  </a>
                </div>
                <div className="vline-s"></div>
                <div className="sub__contac-item">
                  <a href="#0" className="d-flex mb-1 align-items-center gap-1">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.773 13.023L9 17.7959L4.22703 13.023C1.59099 10.3869 1.59099 6.11307 4.22703 3.47703C6.86307 0.84099 11.1369 0.84099 13.773 3.47703C16.409 6.11307 16.409 10.3869 13.773 13.023ZM9 11.25C10.6568 11.25 12 9.90683 12 8.25C12 6.59314 10.6568 5.25 9 5.25C7.34314 5.25 6 6.59314 6 8.25C6 9.90683 7.34314 11.25 9 11.25ZM9 9.75C8.17155 9.75 7.5 9.07845 7.5 8.25C7.5 7.42157 8.17155 6.75 9 6.75C9.82845 6.75 10.5 7.42157 10.5 8.25C10.5 9.07845 9.82845 9.75 9 9.75Z"
                        fill="#F3CB5F"
                      />
                    </svg>
                    <span className="address d-block">Our Location</span>
                  </a>
                  <span className="textp">
                    308/440 Hamilton Rd Chermside QLD 4032 Australia
                  </span>
                </div>
              </div>
              <div className="header-topright-soc">
                <a href="#quote" className="cmn--btn cmn-alt2">
                  <span>Get A Quote</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="main-headerwrap">
          <div className="container">
            <div className="main-navbar">
              <nav className="navbar-custom">
                <div className="d-flex align-items-center justify-content-between">
                  <a href="#home" className="nav-brand d-block d-lg-none">
                    <img
                      className="d-none d-md-block"
                      src={require("../assets/images/logo/logo.png")}
                      alt="logo"
                    />
                    <img
                      className="d-block d-md-none"
                      src={require("../assets/images/logo/logo.png")}
                      alt="logo"
                    />
                  </a>
                  <div className="d-flex align-items-center gap-2">
                    {/* <div className="search-button-head d-lg-none">
                                            <a href="#search">
                                                <i className="bi bi-search fz-20 text-white"></i>
                                            </a>
                                        </div> */}
                    {/* <div className="switch-wrapper-top d-flex d-lg-none"></div> */}
                    {/* <button className="navbar-toggle-btn d-block d-lg-none" onClick={() => setmenuOpen(!menuOpen)} type="button">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </button> */}
                    <div className="d-block d-lg-none">
                      <span className="get-quote-btn">
                        <a href="#quote">Get A Quote</a>
                      </span>
                    </div>
                    <AntIcons.MenuOutlined
                      className="navbar-toggle-btn d-block d-lg-none"
                      onClick={() => setmenuOpen(!menuOpen)}
                      style={{ color: "#f3cb5f", fontSize: "20px" }}
                    />
                  </div>
                </div>
                <div
                  onBlur={() => setmenuOpen(false)}
                  tabIndex={0}
                  className={`navbar-toggle-item ${menuOpen ? "" : "d-none"}`}
                >
                  <div className="d-flex gap-5 flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between mt-4 mt-lg-0">
                    <a
                      href="#home"
                      className="navbar-brand d-lg-block d-none d-xl-none"
                    >
                      <img
                        src={require("../assets/images/logo/logo.png")}
                        alt="logo"
                      />
                    </a>
                    <ul className="custom-nav d-lg-flex d-grid">
                      <li className="menu-item itembg__1 position-relative">
                        <a
                          href="#home"
                          onClick={() => setmenuOpen(false)}
                          className="fw_500"
                        >
                          Home
                        </a>
                      </li>
                      <li className="menu-item itembg__1 position-relative">
                        <a
                          href="#about"
                          onClick={() => setmenuOpen(false)}
                          className="fw_500"
                        >
                          About Us
                        </a>
                      </li>
                      <li className="menu-item itembg__1 position-relative">
                        <a
                          href="#services"
                          onClick={() => setmenuOpen(false)}
                          className="fw_500"
                        >
                          Services
                        </a>
                      </li>
                      <li className="menu-item itembg__1 position-relative">
                        <a
                          href="#quote"
                          onClick={() => setmenuOpen(false)}
                          className="fw_500"
                        >
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
