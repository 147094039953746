import React from 'react';
import Header from './Pages/Header';
import Baner from './Pages/Baner'
import Footer from './Pages/Footer';
import HowItWork from './Pages/HowItWork';
import CleaningQuote from './Pages/CleaningQuote';
import Provide from './Pages/Provide';

const Home = () => {
    return (
        <>
            <Header />
            <Baner />
            <Provide />
            <HowItWork />
            <CleaningQuote />
            {/* Footer  */}
            <Footer />
        </>
    )
}

export default Home